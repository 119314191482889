@import '../src/scss/themes/theme-dark.scss';
@import '../src/scss/themes/theme-light.scss';
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300&display=swap');

html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
}

.aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-container {
  height: 50px;
}

.title-styles {
  font-family: 'Raleway', sans-serif;
  font-size: 250%;
}

.header-icon {
  height: 150px;
  width: 150px;
}

header {
  position: relative;
  height: 520px;
  min-height: 450px;
  width: 100%;
  background-size: cover;
  -webkit-background-size: cover;
  text-align: center;
  overflow: hidden;
  background-color: #e6ed24;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-top: 20px;
  padding-bottom: 0;
}

.language-icon {
  font-size: 50px;
  cursor: pointer;
}

.project-date {
  font-size: 16px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding: 8px 15px 2px 15px;
  position: relative;
  z-index: -500;
  border-top: 5px solid #696969;
  border-radius: 0 0 5px 5px;
  background-color: #696969;
  color: white;
}

.skills-tile {
  background-color: transparentize(#F8F5F4, 0.95);
  padding: 10px 10px 5px 10px;
  width: 100px;
  margin: 5px 0 5px 0;
  border-radius: 8px;
}

.language {
  font-size: 25px;
  background-color: #e6ed24;
  padding-bottom: 10px;
  padding-top: 80px;
}

header h1 {
  font-size: 400%;
  text-align: center;
  font-weight: 600 !important;
  color: #353239;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  margin: 0 auto 18px auto;
  width: 100%;
}

.slider-image {
  border: 5px solid #D7CAAA;
}

.slider-tab {
  background-color: #D7CAAA;
  height: 25px;
}

.slider-iconfiy {
  margin-top: 10px;
}

.styles_typicalWrapper__1_Uvh::after {
  cursor: none !important;
  display: none;
}

#about {
  background: #fff;
  overflow: hidden;
}

#about h1 {
  padding-top: 5%;
  font: 18px/24px 'opensans-bold', sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: black;
  text-align: center;
}

.foto img {
  display: block;
  width: 100%;
}

.foto div {
  background: #ffffff;
  display: inline-block;
  margin: 0 auto 5% auto;
  padding: 10px 10px 5px;
  text-align: center;
  text-decoration: none;
  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
  -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
  box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
  -webkit-transition: all .20s linear;
  -moz-transition: all .20s linear;
  transition: all .20s linear;
  z-index: 0;
  position: relative;
}

.foto div:after {
  color: #333;
  font-size: 25px;
  content: attr(title);
  position: relative;
  top: 15px;
}

.foto div:hover {
  -webkit-transform: scale(1.01);
  -moz-transform: scale(1.01);
  transform: scale(1.01);
  z-index: 10;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
  box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
}

.project-title-settings {
  margin-top: 5%;
  font: 18px/24px 'opensans-bold', sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: black;
  text-align: center;
}

.polaroid img {
  display: block;
  max-width: 100%; /* Para que la imagen se ajuste al contenedor */
  height: auto; /* Mantiene la proporción de la imagen */
  margin: 0 auto; /* Centra la imagen horizontalmente */
}

.polaroid span {
  background: #ffffff;
  display: inline-block;
  margin: 2.7rem 4.5rem 1.8rem;
  padding: 0.9rem 0.9rem 1.8rem;
  text-align: center;
  text-decoration: none;
  -webkit-box-shadow: 0 3.6px 5.4px rgba(0, 0, 0, .3);
  -moz-box-shadow: 0 3.6px 5.4px rgba(0, 0, 0, .3);
  box-shadow: 0 3.6px 5.4px rgba(0, 0, 0, .3);
  -webkit-transition: all .18s linear;
  -moz-transition: all .18s linear;
  transition: all .18s linear;
  z-index: 0;
  position: relative;
  max-width: 600px;
}

@media (width <= 768px) {
  .polaroid span {
    margin: 1.8rem 1.8rem 1.35rem;
    padding: 0.9rem;
  }
}

@media (width <= 480px) {
  .polaroid span {
    margin: 1.6rem;
    padding: 0.95rem;
  }
}

@media (width >= 1200px) {
  .polaroid span {
    max-width: 50%;
  }
}

@media (width >= 1600px) {
  .polaroid span {
    max-width: 40%;
    margin: 2rem auto;
  }
}

.link-href {
  color: black;
}

.wave {
  font-size: 160%;
}

.font-trebuchet {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

#preview {
  width: 500px;
  background-color: #ebeaf5;
  padding: 15px;
  position: relative;
  margin-bottom: 15%;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

#resume {
  background: #e6ed24;
}

.experience-icon {
  font-size: 300%;
  margin-top: 25%;
  text-align: center;
}

.main-badge {
  font-size: 13px !important;
  text-align: left !important;
  padding: 5px 8px 5px 8px !important;
  vertical-align: baseline;
  background-color: #6b6e10 !important;
  color: white;
  font-weight: lighter !important;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.experience-badge {
  font-size: 11px !important;
  text-align: left !important;
  padding: 5px 8px 5px 8px !important;
  vertical-align: baseline;
  background-color: #eeeeee !important;
  color: black;
  font-weight: lighter !important;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.vertical-timeline-element-date {
  padding: 0 !important;
  margin: 0 !important;
}

@media only screen and (max-width: 1170px) {
  .experience-icon {
    font-size: 170%;
    margin-top: 27%;
    text-align: center;
  }
}

.modal-inside .modal-content {
  background: white;
}

.bars {
  width: 95%;
  float: left;
  padding: 0;
  text-align: left;
}

.bars .skills {
  margin-top: 36px;
  list-style: none;
}

.bars li {
  position: relative;
  margin-bottom: 60px;
  background: #ccc;
  height: 42px;
  border-radius: 3px;
}

.bars li em {
  font: 15px 'opensans-bold', sans-serif;
  color: #313131;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: normal;
  position: relative;
  top: -36px;
}

.bar-expand {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding-right: 24px;
  background: #313131;
  display: inline-block;
  height: 42px;
  line-height: 42px;
  border-radius: 3px 0 0 3px;
}

.modal-close {
  text-align: right;
  padding: 10px 15px 10px 15px;
  cursor: pointer;
}

.close-icon {
  color: black;
  font-weight: lighter !important;
}

.modal-description {
  text-align: justify;
  padding: 5px 5px 0 5px;
  margin-bottom: 20px;
  font-size: 12px;
}

.awssld__next {
  outline: none !important;
}

.awssld__prev {
  outline: none !important;
}

.loader-bar-color {
  color: black !important;
}

#skills {
  background: #1F1F1F;
  min-height: 200px;
  width: 100%;
  overflow: hidden;
  padding-bottom: 10%;
}

.section-title {
  padding-top: 5%;
  padding-bottom: 5%;
  font: 18px/24px 'opensans-bold', sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #fff;
  text-align: center;
}

.skill-icon {
  font-size: 180%;
  color: white;
  text-align: center;
  position: relative;
}

footer {
  background: #1F1F1F;
  min-height: 100px;
  width: 100%;
  overflow: hidden;
  font-size: 14px;
  color: white;
  position: relative;
  text-align: center;
}

footer a,
footer a:visited {
  color: #fff;
}

footer a:hover,
footer a:focus {
  color: #fff;
}

.social-links {
  margin-top: 50px;
  font-size: 22px;
}

.company-link {
  font-weight: bold;
  text-decoration: none;
  color: #000;
}

.company-link:hover {
  font-weight: bold;
  text-decoration: none;
  color: #000;
}
